import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const cloudNativeEngProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Cloud native engineering to build scalable, reliable and operationally{' '}
        <span className="heading-red">excellent digital products</span>
      </>
    ),
    bannerContent:
      'Legacy tech can’t keep up with modern business requirements anymore. We help you leverage cloud-native technologies to build auto-scaling infrastructures, curate delightful customer experiences, and architect cutting-edge digital solutions.',
    BannerAlt: 'Cloud Native Engineering',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Become intrinsically agile and improve time to market with{' '}
        <span className="highlight-head">cloud-native solutions</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Modern digital experiences require smarter methodologies, better
            technologies, and innovative approaches to win users’ trust and
            attention. Cloud-native technologies have the potential to deliver
            sophisticated software solutions with such experiences.
            <br /> <br />
            However, you need a reliable partner who can oversee the perfect
            implementation of cloud-native tech to deliver those delightful
            digital products to your customers. With 12+ years of experience,
            800+ cloud experts in the ranks, and Fortune500 companies as
            satisfied clients, Simform equips you with the right expertise to
            make the most of cloud-native technologies.
            <br />
            <br />
            Simform gives you access to seasoned cloud architects, skilled
            DevOps experts, cross-functional agile teams, and proficient data
            engineering experts. Whether it be modernizing an aging software,
            building a solution from scratch, or modifying infrastructure to
            keep up with business growth, Simform utilizes the best cloud
            practices and methodologies to fulfill your business goals.
          </>
        ),
      },
      {
        detailsList: [
          'Expert cloud-consultation',
          'Seasoned cloud-architects',
          'Scalable infrastructure development and management',
          'Robust DevOps implementations',
          'Automated CI/CD pipelines',
          'Rigorous quality assurance and testing',
          'Logical data lake implementations',
          'Secure data management practices',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our cloud-native <span className="highlight-head">services</span>
      </>
    ),
    servicesPara:
      'Choose cloud-native capabilities to solve complex business challenges, support your growing business, and deliver customer-centric solutions. Simform offers a range of cloud-native services to help navigate your way to business objectives.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Cloud development services',
        className: 'custom-software',
        mainPara: (
          <>
            Maximize your business value by adopting end-to-end cloud solutions.
            With scalable computing resources at focus, we help you migrate
            resource-intensive processes, bringing better business agility and
            achieving the flexibility your business needs.
          </>
        ),
        listItem: [
          {
            title: 'Cloud-native app development',
            para:
              'Simform’s expertise in terms of architecture patterns and best practices help you make the best use of cloud-native technologies to create, deploy, adopt and upgrade cloud-native applications.',
          },
          {
            title: 'Serverless',
            para:
              'Use our serverless application development and consulting services and forget about the backend provisioning or maintenance and focus solely on delivering an exceptional user experience to your customers.',
          },
          {
            title: 'Cloud architecture design and review',
            para:
              'Implement the latest cloud capabilities, and minimize system downtime with proven cloud architecture design services and tested strategies.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-development/',
      },
      {
        heading: 'DevOps services',
        className: 'software-testing',
        mainPara:
          'Sync your development and operational capabilities to accelerate product lifecycle with our DevOps managed services. With continuous integration and delivery at the focus, we help you automate processes, strengthen app security, upgrade architecture, and more.',
        listItem: [
          {
            title: 'DevOps consulting',
            para:
              'We devise the best DevOps implementation strategies to help streamline your workflows, automate CI/CD pipelines, accelerate software delivery, and adhere to the highest development standards.',
          },
          {
            title: 'Cloud infrastructure management',
            para:
              'Get your cloud infrastructure ready to support a fast-paced delivery model, powered by DevOps, with your management services. We help you keep downtimes at bay, manage resources efficiently, and scale seamlessly.',
          },
          {
            title: 'CI/CD services',
            para:
              'Keep up with evolving market trends and user requirements with DevOps practices that help you deploy and manage CI/CD pipelines. Roll out innovative solutions at a much greater speed while resolving issues at a higher cadence with our expert CI/CD services.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/devops/',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'There is no one-size, one-idea-fits-all kind of solution. At Simform, our software engineers are versatile and constantly improve on their skillset who can adapt and evolve according to the requirement of your application development.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            Our expert development team dedicates 100% of their time to the
            assigned client. They also nurture long-term relationships and gain
            in-depth knowledge of their business domain.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            Our strong pillar is that we follow adaptive planning in an agile
            approach to build apps flexibly. This helps us reduce repetition in
            work and empower cross-functional team collaboration to deliver
            projects on time.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End-to-end services',
        workingBenefitsContent: (
          <>
            We have a proven record of satisfied clients. Be its cloud
            solutions, development or mobile, web or desktop apps, creation of
            custom, enterprise, or SaaS apps. We have a team ready for your
            every need.
          </>
        ),
        benefitImgAlt: 'End-to-end services',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable team',
        workingBenefitsContent: (
          <>
            Build a distributed team filled with the right talent that can meet
            different market demands. Incorporate additional developers who can
            expand your existing team and add value to your project.
          </>
        ),
        benefitImgAlt: 'Scalable team',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Unmatched quality',
        workingBenefitsContent: (
          <>
            Beyond development, we take it seriously to make sure the deployed
            product is compliant with set standards for security, performance,
            dependencies, and so much more. We make room for constant
            improvement.
          </>
        ),
        benefitImgAlt: 'Unmatched quality',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        Software Development in <br />
        <span className="highlight-head">Cutting-Edge Technologies</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Big Data',
        serviceParagraph: (
          <>
            Handle enormous amounts of data and limitless tasks virtually using
            cloud service-compatible frameworks like MongoDB, Hadoop, etc.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Internet of Things (IoT)',
        serviceParagraph: (
          <>
            Scale your application smartly. Ranging from middleware to
            architecture creation, our team builds sleek IoT interfaces for all
            MVP.
          </>
        ),
        serviceIcon: images.sInternetofThings,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Artificial Intelligence (AI)',
        serviceParagraph: (
          <>
            Harness of the power of automation. We create AI capabilities that
            are reliable and interpretable with your software architecture.
          </>
        ),
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'VR and AR app Development',
        serviceParagraph: (
          <>
            Leverage the power of VR and AR. Tap into our WebVR, photogrammetry,
            and Storyboarding skills in development.
          </>
        ),
        serviceIcon: images.svrArAppDev,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Machine Learning (ML)',
        serviceParagraph: (
          <>
            Develop your app intelligently. Build, train, and deploy ML models
            for various use cases of your application as desired.
          </>
        ),
        serviceIcon: images.smachineLearning,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Blockchain',
        serviceParagraph: (
          <>
            Create DApps, build multi-blockchain compatible features, smart
            contracts with cryptocurrency solutions, and integrate REST APIs.
          </>
        ),
        serviceIcon: images.sblockchain,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Cloud Native</span>
        <br />
        Engineering Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'White-labeled – cloud native – auctioning platform for auction houses',
        caseStudiesParaGraph:
          '...Managed Engineering Teams, Cloud Architecture Design and Review, White Label Development…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/extended-platform-for-auctioning-platform/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle:
          'An mHealth system to eradicate Rabies from the world',
        caseStudiesParaGraph:
          '...mHealth and precision analytics for a WHO backed NGO vaccinating over 1M dogs/year...',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/mission-rabies/',
      },
      {
        caseStudiesTitle:
          'Bringing Food Trucks and Property owners together to revolutionise $2 Billion Food Industry',
        caseStudiesParaGraph:
          '…a web-based platform which eases the process of finding, booking, and scheduling properties in the Food Truck Business...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/food-truck-spaces/',
      },
      {
        caseStudiesTitle:
          'Simplifying the Logistics and Transportation Industry of North America',
        caseStudiesParaGraph:
          '…An end-to-end freight brokerage system that automates day-to-day functions of today’s logistics professionals…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  workFlowProps: {
    heading: (
      <>
        Software Development <br />
        <span className="highlight-head">Process</span>
      </>
    ),
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.iconTeam,
        workFlowIconAlt: 'Choosing the team',
        workFlowTitle: 'Choosing the team',
        workFlowParagraph:
          'We pick a talent pool with the right team, the right expertise, domain knowledge, and willingness to adapt and evolve to your project environment.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.iconSprint,
        workFlowIconAlt: 'Development methodology planning',
        workFlowTitle: 'Development methodology planning',
        workFlowParagraph:
          'Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.iconTech,
        workFlowIconAlt: 'Architecture creation',
        workFlowTitle: 'Architecture creation',
        workFlowParagraph:
          'We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
    ],
    workFlowSecondRows: [
      {
        workFlowClass: 'light-green',
        workFlowCount: '4',
        workFlowIcon: images.iconStandUps,
        workFlowIconAlt: 'Project Delivery',
        workFlowTitle: 'Project Delivery',
        workFlowParagraph:
          'Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '5',
        workFlowIcon: images.iconCodeReviews,
        workFlowIconAlt: 'Intelligent Testing',
        workFlowTitle: 'Intelligent Testing',
        workFlowParagraph:
          'We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project.',
        workFlowArrowImg: images.iconFlowArrow5,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '6',
        workFlowIcon: images.iconIterate,
        workFlowIconAlt: 'Periodical reviews',
        workFlowTitle: 'Periodical reviews',
        workFlowParagraph:
          'We conduct timely reviews to squash any foreseeable concern,  present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page.',
        workFlowArrowImg: images.iconFlowArrow6,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Travel',
        contentHeading: 'Rent-a-Car Mobile App',
        contentPara:
          'Create a multilingual application that supports reviews, ratings, photos, 24/7 support with multi-payment gateway features to make the process of travel app services smooth and secured.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile app for budgeting',
        contentPara:
          'Provide users with capabilities to calculate balances, manage personal investments, features to transfer money, and plan weekly/month budgets. We integrate advanced security APIs to ensure there is no breach.',
        contentSingleProps: [
          {
            singleblkImg: images.mobbanking1,
            singleblkImg2x: images.mobbanking12x,
          },
          {
            singleblkImg: images.mobbanking2,
            singleblkImg2x: images.mobbanking22x,
          },
          {
            singleblkImg: images.mobbanking3,
            singleblkImg2x: images.mobbanking32x,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: 'On-demand streaming Mobile/Web App',
        contentPara:
          'Attract users to your steaming platform with advanced analytics powered by AI, ML, and cloud power to create live-stream and online streaming from servers along with features of a recommendation engine, sharing, and tracking of user behavior.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: 'Warehouse Management Application',
        contentPara:
          'Build a custom SaaS application to manage inventory, stock-in, stock-out, etc., and serve as a central point for managing all B2B operations of the business. We can also create such Paas or APIaaS.',
        contentSingleProps: [
          {
            singleblkImg: images.b2b1,
            singleblkImg2x: images.b2b12x,
          },
          {
            singleblkImg: images.b2b2,
            singleblkImg2x: images.b2b22x,
          },
          {
            singleblkImg: images.b2b3,
            singleblkImg2x: images.b2b32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'eReporting Mobile App',
        contentPara:
          'Leverage the power of the cloud to build a data management application where all patient reports are stored online for easier and quicker access. We create features for data storing, indexing, cache management, and dependency handling.',
        contentSingleProps: [
          {
            singleblkImg: images.doctor1,
            singleblkImg2x: images.doctor12x,
          },
          {
            singleblkImg: images.doctor2,
            singleblkImg2x: images.doctor22x,
          },
          {
            singleblkImg: images.doctor3,
            singleblkImg2x: images.doctor32x,
          },
        ],
      },
      {
        tabName: 'NGO',
        contentHeading: 'Public Welfare App',
        contentPara:
          'Development of a mobile application that stores necessary demographic detail of the user, which can be correlated against healthcare and food industry to serve the underprivileged in real-time. Predictive analysis through data science programs written for ML model is used in large here.',
        contentSingleProps: [
          {
            singleblkImg: images.publich1,
            singleblkImg2x: images.publich12x,
          },
          {
            singleblkImg: images.publich2,
            singleblkImg2x: images.publich22x,
          },
          {
            singleblkImg: images.publich3,
            singleblkImg2x: images.publich32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'Virtual Classroom App',
        contentPara:
          'Build a mobile and web app where CMS and CDN are leveraged to deliver content at its best to the students virtually. The app environment is also created with high data management tools to ensure easy access and designed in a way to maintain smooth navigation.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail breaking down',
        contentHeading: 'Social Marketplace App',
        contentPara:
          'Develop an app where sellers and purchasers can interact without any middleman to buy and rent food trucks and land spaces. Usage of microservices plays a role in breaking down multiple large operations into smaller services decoupling all tasks.',
        contentSingleProps: [
          {
            singleblkImg: images.mobecomm1,
            singleblkImg2x: images.mobecomm12x,
          },
          {
            singleblkImg: images.mobecomm2,
            singleblkImg2x: images.mobecomm22x,
          },
          {
            singleblkImg: images.mobecomm3,
            singleblkImg2x: images.mobecomm32x,
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/blog/ci-cd-process/',
        featurecardTitle:
          'CI/CD Process: 6 Efficient ways for Continuous Improvement in SDLC',
      },
      {
        blogLink: 'https://www.simform.com/blog/microservice-best-practices/',
        featurecardTitle: '10 Microservice Best Practices: The 80/20 Way',
      },
      {
        blogLink: 'https://www.simform.com/blog/devops-outsourcing/',
        featurecardTitle:
          'DevOps Outsourcing: How it Works, Benefits & How to Get Started',
      },
    ],
  },
}
